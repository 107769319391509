import React, { useState } from "react";
import foodprocess from "../../assets/images/fop.png";
import efi from "../../assets/images/efi.png";
import livestock from "../../assets/images/l.png";
import ate from "../../assets/images/ate.png";
import fp from "../../assets/images/fp.png";
import "./ImageCard.css";

const Ouroffering = () => {
  return (
    <div className="w-full px-2 sm:px-4 md:px-8 lg:px-32">
      <div className="py-24">
        <p className="py-4 text-green-700"> Product Quality</p>
        <div className="grid md:grid-cols-2 gap-24">
          <h2 className="lg:text-3xl text-gray-700 lg:pb-10 sm:text-l font-bold">
            We take great pride in the quality of our agricultural produce and
            agro-allied products.
          </h2>
          <p className="">
            {" "}
            From fresh fruits and vegetables to processed goods, each item
            undergoes stringent quality checks to ensure it meets our high
            standards. Our commitment to excellence has earned us the trust of
            numerous customers, both locally and internationally.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols- gap-6 w-full">
        <div className="relative  overflow-hidden rounded-lg  cursor-pointer">
          {/* <img className="object-cover w-full h-48" src="https://images.unsplash.com/photo-1502581827181-9cf3c3ee0106?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=420&q=80" alt="Flower and sky"/> */}

          <div className="absolute bottom-10 left-0 px-6 py-4">
            <h4 className="mb-3 text-xl font-semibold tracking-tight lg:text-3xl text-green-700">
              Explore Our Offerings
            </h4>
            <p className="leading-normal text-black">
              Here are some agricultural related services we offer.
            </p>
          </div>
        </div>

        <CardItem
          image={foodprocess}
          title="Fresh Organic Produce"
          description="Delivering the freshest and healthiest organic produce to your table. Our farm is a haven for naturally grown fruits, vegetables, and herbs, free from harmful chemicals and additives."
        ></CardItem>

        <CardItem
          image={livestock}
          title="Livestock Rearing"
          description="Experience the richness of farm-fresh dairy and ethically raised poultry from our livestock operations. We prioritize animal welfare, ensuring that our livestock is healthy, happy, and sustainably raised."
        ></CardItem>

        <CardItem
          image={efi}
          title="Eco-Friendly Initiatives"
          description="SCL Agro Allied Farm is committed to environmental stewardship. We implement eco-friendly practices, sustainable packaging, and waste reduction efforts to protect our planet for future generations.."
        ></CardItem>

        <CardItem
          image={ate}
          title="Agri-Tourism and Education"
          description="Immerse yourself in the world of agriculture through our agri-tourism experiences and educational programs. Learn about sustainable farming, interact with farm animals, and reconnect with nature."
        ></CardItem>

        <CardItem
          image={fp}
          title="Agro Processing"
          description="Through our state-of-the-art agro processing facility, we add value to raw agricultural products, offering a range of premium processed goods while maintaining their nutritional integrity."
        ></CardItem>
      </div>
    </div>
  );
};

function CardItem({ image, title, description }) {
  const [isHovered, setIsHovered] = useState(true);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className="relative hovering-card overflow-hidden rounded-lg shadow-sm cursor-pointer"  
    onMouseEnter={handleHover}
    onMouseLeave={handleHover}>
      <div>
        <img
          className="object-cover w-full  "
          src={image}
          alt="Flower and sky"
        />
        <div
          className={`i-title absolute bottom-10 px-6 py-4 left-0 ${isHovered ? "hovered" : ""}`}
         
        >
          {" "}
          {isHovered && 
          <h4 className=" text-xl font-semibold tracking-tight text-white">
            {title}
          </h4>
          }
        </div>

        <div className="absolute card-content  bottom-0 left-0 px-6 py-4">
          <h4 className="mb-3 text-xl font-semibold tracking-tight text-white">
            {title}
          </h4>
          <p className="leading-normal text-gray-100">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default Ouroffering;
