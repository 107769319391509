import React from "react";
// import './Styles/style.css'
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaLinkedin,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
import { colors } from "../constants/theme";

const Footer = () => {
  const info = "items-center";
  const text = " li md:text-sm text-xs text-white md:ml-3 py-1";
  return (
    <div
      className="w-full mygray text-white py-10 md:px-20 px-5 bg-black"
      // style={{ backgroundColor: colors.gray, fontFamily: "Poppins" }}
    >
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-4  md:pt-16  pb-8">
        <div className="md: my-10">
          <h6
            className="font-bold md:text-lg text-sm uppercase pt-2"
            style={{ color: "#EEBE12" }}
          >
            Services
          </h6>
          <ul>
            <li className={text}>Apiculture</li>
            <li className={text}>Warehousing</li>
            <li className={text}>Green House Farming</li>
          </ul>
        </div>
        <div className="md:space-y-2 md: my-10">
          <h6
            className="font-bold uppercase pt-2 md:text-lg text-sm"
            style={{ color: "#EEBE12" }}
          >
            contact
          </h6>
          <div className={`${info}`}>
            {/* Icon */}
            <div className="flex items-center">
              <MdEmail color={"white"} size={30} />
              <p className={"md:text-lg text-xs text-white ml-3"}>
                info@sclng.com
              </p>
            </div>
            <div className="flex flex-col my-6 ">
          <BiPhoneCall className='inline' color={"white"} size={30} /> 
             
              <p className={"md:text-lg text-xs text-white ml-3"}>
               <ul><li className ='li'> SCL HO Maitama:
                <br /> 234 (0) 911 731 2324 
                </li >
                <li className ='li'>
                SCL Operations Office Kwali:
                <br /> 234 (0) 911 742 7795
                </li>
                </ul>
              </p>
            </div>
          </div>
          <div className={"flex items-center"}>
            {/* Icon */}
            <FaMapMarkerAlt color={"red"} size={30} />
            <p className={"md:text-lg text-xs text-white ml-3"}>
              SCL Farms, Dama-kusa Village, Kwali Area Council, Abuja.
            </p>



          </div>
        </div>
        <div className="col-span-2 md:px-0 px-4">
          <p className="font-bold uppercase" style={{ color: "#EEBE12" }}>
            Subscribe to our newsletter
          </p>
          <p className="py-4" style={{ color: "white", fontSize: "12px" }}>
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
          <form className="flex flex-col  items-end">
            <input
              className="w-full p-2 md:mr-4 rounded-md mb-4"
              type="email"
              placeholder="Enter email.."
            />
            <button
              className=" p-2 mb-4 rounded-md text-gray-500"
              style={{ backgroundColor: "#000" }}
            >
              Subscribe
            </button>
          </form> 
        </div>
      
      </div>
      <h1 className="text-2xl bold my-2">Locate Us</h1>
      <iframe className="mb-8 rounded-xl "
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d252331.0314923284!2d6.65552258671875!3d8.816817800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e8da07256a94b%3A0x884f2a30eaaa2a88!2sSCL%20Agroindustrial%20Farm!5e0!3m2!1sen!2sng!4v1707822757727!5m2!1sen!2sng"
         width="100%" height="450" 
         style={{border:0}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      
      <div className="md:flex my-4 border-t-2 border-gray-600 flex-col-reserve max-w-[1240px] px-2   mx-auto justify-between text-center text-white text-sm">
        <p className="py-4">Copyrights 2016 - 2024 SCL. All rights reserved</p>
        <div className="flex  space-x-4 md:justify-end justify-start md:mb-0 mb-5 sm:w-[300px] pt-4 text-2xl">
       <a href='https://web.facebook.com/SCLNigeria/'>   <FaFacebook />
       </a>
         <a href="https://instagram.com/sclnigeria/"><FaInstagram /></a> 
        <a href ="https://twitter.com/SCLNigeria">  <FaTwitter /></a>

        <a href ="https://www.linkedin.com/company/scl-nigeria/">  <FaLinkedin /></a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
