const menu_items = [
  { name: "Home", link: "/" },
  { name: "About Us", link: "/about" },
  { name: "Products", link: "/products" },
  { name: "Gallery", link: "/gallery" },
];
const menu_items_two = [
  { name: "SCL Academy", link: "/academy" },
  { name: "Contact", link: "/contact-us" },
];
export { menu_items, menu_items_two };
// { name: "SCL Juriya Dashboard", link: "/project-juriya-dashboard" },
