import React from "react";

const YearOneHome = () => {
  return (
    <div className="w-full">
      <div>
        <iframe
          title="SCL - Project Juriya - Year 1"
          width="100%"
          height="600"
          src="https://app.powerbi.com/view?r=eyJrIjoiNDVhNzEwMTgtZmVlZS00MTI0LWJlZmQtZDA2MDg3NTZkNmY2IiwidCI6ImQ3MTg2MGNiLTE4MWQtNDQ0Zi05MDBjLTkyMjJhZjg2MDYwZCJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </div>
  );
};

export default YearOneHome;
