import React from "react";

const YearTwoFAassessHome = () => {
  return (
    <div className="w-full h-screen">
      <div>
        <iframe
          title="SCL - Project Juriya - Year 2 FA Performance Dashboard"
          width="100%"
          height="600"
          src="https://app.powerbi.com/view?r=eyJrIjoiMmI0MmEwZTAtOWNlZi00MDJiLTg5N2UtNmNlN2YyODJkNTdjIiwidCI6ImQ3MTg2MGNiLTE4MWQtNDQ0Zi05MDBjLTkyMjJhZjg2MDYwZCJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
 
      </div>
    </div>
  );
};

export default YearTwoFAassessHome;
