import React from "react";
import t1 from "../../assets/images/about/1.png";
import t2 from "../../assets/images/about/2.png";
import t3 from "../../assets/images/about/3.png";
import aimg3 from "../../assets/images/aimg3.png";
import aimg from "../../assets/images/aimg.png";
import tray from "../../assets/images/tray.png";
import { FaAward, FaRegThumbsUp } from "react-icons/fa";
import { GiTrophyCup } from "react-icons/gi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { colors } from "../../constants/theme";
import Button from "../Button";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const data = [
    { name: "Quality Design", Icon: FaAward },
    { name: "Best Honey", Icon: GiTrophyCup },
    { name: "Best Organic", Icon: BsFillPatchCheckFill },
    { name: "Top Class", Icon: FaRegThumbsUp },
  ];

  return (
    <div>
      <div className="home" style={{ height: "150px", width: "100%" }}>
        <p className="font-bold text-4xl text-white z-10 text-center py-16">
          About Us
        </p>
      </div>

      <div className="grid lg:grid-cols-2 lg:mx-32 lg:my-16 items-center px-4 gap-8">
        <div className={"md:block items-center  pb-5 pt-2"}>
          <h6 className="text-green-800 font-bold my-4">Who we are</h6>
          <h1 className="lg:text-3xl text-gray-700 text-2xl font-bold">
          Sa'i'anwara'I'jumai Consultaire Limited (SCL)
          </h1>
          {/* <h1 className="lg:text-4xl text-gray-700 text-2xl font-bold">
            Welcome to Sa'I'Anwara'I Ju'Mai Consultaire Limited (SCL) Farms -
            Your Trusted Partner in Agriculture and Agro-Allied Solutions!
          </h1> */}
          <p className="mt-8 font-light lg:text-md leading-relaxed text-left pr-10  pt-1 mb-2">
            Experience has taught us that the best way to acquire knowledge is
            “learning by doing”. So, our Farm-Farmer School, located in the
            heart of the over 100-hectare farm affords us the environment to
            keep trainees in situ while they learn and practice the principles
            of regenerative agriculture for effective extension service delivery
            and hand-holding of our regenerative-agriculture-adopting
            communities. Our Farm-Farmer School is equipped with boarding
            facilities, a central refectory, demo plots and recreation
            facilities all sustainably solar powered. We offer trainings for
            farmers, institutions, policy makers, trainers and extension agents.
          </p>

          <div className="col-span-2 mt-12 ">
          <h1 className="lg:text-3xl mt-8 text-gray-700 text-2xl font-bold">
        Vision
          </h1>
         
          <p className="mt-2 font-light lg:text-md leading-relaxed text-left pr-10  pt-1 mb-2">
            
          To realize the lost glory of Agriculture and its imperative importance in the sustenance of the Nigerian People.
             </p>

             <h1 className="lg:text-3xl mt-8  text-gray-700 text-2xl font-bold">
         Mission
          </h1>
         
          <p className="mt-2 font-light lg:text-md leading-relaxed text-left pr-10  pt-1 mb-2">
          To produce premium products and services with the aid of modern concepts and technology, thus making Agro-Allied Business a major source of economic empowerment and livelihood to Nigerians. 
              </p> 
        

              <h1 className="lg:text-3xl mt-8 text-gray-700 text-2xl font-bold">
              4i Values
          </h1>
         
          <p className="mt-2 font-light lg:text-md leading-relaxed text-left pr-10  pt-1 mb-2">
          Integrity, Intensity, Innovation, and Involvement.
               </p> 
          </div>
        </div>

        <div className="">
          <img className="w-full" src={aimg} />
        
        </div>
      </div>

      <div className="w-full px-2 sm:px-4 md:px-8 mb-24 lg:px-32">
        <p className="py-4 text-green-700"> coaching and mentorship</p>

        <div className="grid md:grid-cols-2  justify-between gap-6">
          <div className="">
            <h2 className="lg:text-2xl text-gray-700 sm:text-l font-bold mb-12">
              We also have a team of dedicated professionals who provide
              coaching and mentoring in agri-businesses for young entrepreneurs
              and new generation of farmers.
            </h2>
          </div>

          <div className="">
            <p className=" font-light lg:text-md leading-relaxed ">
              {" "}
              SCL also train youths and women on entrepreneurship in general and
              livestock based vocational enterprise development specifically,
              thereby building local capacities in sustainable and profitable
              fish, poultry and livestock farming ventures as well as in crop
              production, processing and marketing of agro-produce.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-3 mt-4  gap-4 items-center">
        <img className="w-full" src={t1} />
          <img className="w-full" src={t2} />
          <img className="w-full" src={t3} />
      </div>
       
      </div>

      <div className="flex text-center lg:px-60 lg:py-20 justify-items-center justify-between">
        <div>
          <h2 className="lg:text-3xl lg:px-48 text-gray-700 sm:text-l font-bold mb-12">
            Join Us On Our Journey
          </h2>
          <p>
            At SCL Farms, we believe in a future where agriculture sustains
            communities, supports livelihoods, and protects the planet.
            Together, let's cultivate a greener, healthier, and more prosperous
            world. Join us on this incredible journey as we sow the seeds of
            change and harvest a bountiful future. Get in touch with us today
            and explore how we can collaborate for a more sustainable and
            prosperous agricultural future. Thank you for visiting SCL Farms!
            Sa’I Anwara ’I’ Jumai Consultaire Limited (SCL) Farms Your Trusted
            Partner in Agriculture and Agro-Allied Solutions. SCL Farms -
            Nurturing Growth, Harvesting Success.
          </p>
         
          <div className=" justify-items-center	my-6	  ">
            <Link to='/contact-us'>
      <button type="button" className=" mx-auto	text-white bg-green-700 hover:bg-green-800 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ">Get In Touch</button>

      </Link>
      {/* <Button text="Get In Touch" /> */}

      </div>
        </div>
       
         
      </div>
    
    </div>
  );
};

export default AboutUs;
