import React from "react";
import { colors } from "../constants/theme";
import aboutimg from "../assets/images/about-img.png";

const About = () => {
  return (
    <div className="w-full ">
      {/* content */}
      <div className="w-full  lg:p-32 md:px-12 px-4 py-10 md:py-10">
       

      <div className="grid lg:grid-cols-2 justify-items-stretch items-center   gap-4">
        <div className="">
            <div className="pb-6">
            <h1
              className="font-bold lg:text-3xl  text-left text-gray-700"
              // style={{ color: colors.primary }}
            >
              An Agro-Allied Company with investment and developmental focus on “people, crops, livestock and environment.
            </h1>
            {/* <p className=" font-light text-gray-600 lg:text-md text-left pt-1 mb-2">
              People. Crops. Livestock. Environment
            </p> */}
            </div>
            {/* <p className="text-left text-l lg:text-lg  font-light lg:pr-20  text-black">
              SCL is a fully indigenous limited liability Agro-Allied company
              registered in 2010. The company was incorporated to carry out
              variety of Agro-Allied businesses and services.   Our major strength is our highly trained technical staff base, led by
            a board and management team with over 150 years cumulative corporate
            management experience.
            </p> */}
            <p className="text-left text-l lg:text-lg leading-relaxed font-light lg:pr-20  text-black">SCL is a fully indigenous limited liability Agro-Allied company registered in 2010. The company was incorporated to carry out variety of Agro-Allied businesses and services.
Our major strength is our highly trained technical staff base, led by a board and management team with over 150 years cumulative corporate management experience. 
At SCL, we are passionate about transforming the agricultural landscape and contributing to sustainable development. With a deep-rooted commitment to excellence, innovation, and community welfare, we stand as a reliable partner in agriculture, empowering farmers, and enriching lives.
</p>
          
          {/* <p className=" text-l font-light lg:text-lg  lg:pr-20 text-black "  >With investment and developmental focus on “people, crops, livestock and environment”, SCL prides itself in its ability to proffer practical, effective and efficient solutions and strategies for sustainable and profitable organizational growth, societal development and livelihood improvements that lead sustainable poverty reduction through a harmonious and effective utilization of natures endowments and technology to drive social change. In addition to commercial farming and agro-produce processing</p> */}
        </div>

        <div className="">
          <img src={aboutimg} className="" />
        </div>
      </div>
      </div>














      {/* <div className="bg-green-50 w-full grid grid-cols-1 md:grid-cols-2 md:space-x-24 max-w-[1240px] lg:px-32 md:px-24 px-10 py-10 md:py-10">
        <div className="md:pr-20 pr-0">
          <h1
            className="font-bold text-lg md:text-xl text-left"
            style={{ color: colors.primary }}
          >
            Agro-Allied Company
          </h1>
          <p className=" font-light text-gray-600 md:text-md text-sm text-left pt-1 mb-2">
            People. Crops. Livestock. Environment
          </p>
          <div className="bg-gray-200 px-12 py-4">
            <p className="text-justify text-xs font-light  text-black">
              SCL is a fully indigenous limited liability Agro-Allied company
              registered in 2010. The company was incorporated to carry out
              variety of Agro-Allied businesses and services.
            </p>
            <div className="w-full flex justify-center items-center py-5"></div>
            {/* <p className="text-justify text-xs font-light  text-black">
              Our core philosophy of building sustainable and profitable crop,
              livestock and agro-allied businesses and entrepreneurs based on
              the understanding of the interconnection between the environment,
              livestock and people.
            </p> *
          </div>
        </div>
        <div className="flex justify-between items-start mt-10">
          {/* <img src={aboutimg} className="w-[160px] h-[260px]" />
          <img src={aboutimg} className="w-[160px] h-[260px]" /> 
        </div>
      </div> */}
      {/* Mission and Vission */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-32 max-w-[1240px] lg:mx-56 md:mx-32 mx-10 py-2 md:py-2">
        <div className="my-2 py-5 border-t lg:border-white md:border-white border-t-yellow-400">
          <h1
            className="text-4xl font-bold py-2"
            style={{ color: colors.primary }}
          >
            Vision
          </h1>
          <p className="text-justify text-sm  primary-font md:pr-20 pr-0">
            To realize the lost glory of Agriculture and its imperative
            importance in the sustenance of the Nigerian People.
          </p>
        </div>
        <div className="my-2 py-5 border-t lg:border-white md:border-white border-t-yellow-400">
          <h1
            className="text-4xl font-bold py-2 "
            style={{ color: colors.primary }}
          >
            Mission
          </h1>
          <p className="text-justify text-sm  primary-font">
            To produce premium products and services with the aid of modern
            concepts and technology, thus making Agro-Allied Business a major
            source of economic empowerment and livelihood to Nigerians.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default About;
