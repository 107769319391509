import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { colors } from "../constants/theme";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full">
      <div className="w-full">
        <Navbar />
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="max-w-md mx-auto text-center p-8">
            <h1
              className="text-4xl font-semibold mb-4"
              style={{ color: "#FF4D00" }}
            >
              Oops! Page Not Found
            </h1>
            <p className="text-gray-600 mb-6">
              The requested page does not exist.
            </p>
            {/* <img
          src="/images/404-image.png" // Add your own image URL
          alt="404 Illustration"
          className="mx-auto mb-6"
        /> */}
            <Link
              to={"/"}
              style={{ color: colors.primary }}
              className=" hover:underline"
            >
              Go back to Home
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
