import React, { useState } from "react";
import "./ImageCard.css";

const ImageCard = ({ imageUrl, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className={`image-card ${isHovered ? "hovered" : ""}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <img src={imageUrl} alt="Card" />
      {isHovered && <div className="description px-8">{description}</div>}
    </div>
  );
};

export default ImageCard;
