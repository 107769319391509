import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import About from "../components/About";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import IconsSection from "../components/IconsSection";
import MissionVision from "../components/MissionVision";
import Navbar from "../components/Navbar";
import Support from "../components/Support";
import ValuesSection from "../components/ValuesSection";
import VideoSection from "../components/VideoSection";
import FieldAgentForm from "../components/FieldAgentForm";
import { RegButton } from "../components/RegButton";
import Whysection from "../components/Whysection";
import Ouroffering from "../components/Offering/Ouroffering";
import Whychooseus from "../components/Whychooseus";
import Accordionsection from "../components/Accordionsection";
import Offering from "../components/Offering";

const Home = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div style={{ transition: "500ms" }}>
          {/* <Header /> */}
          <Navbar />
          <Hero />
          <About />
          {/* <IconsSection /> */}
          {/* <ValuesSection /> */}
          {/* <Support /> */}
          <Ouroffering></Ouroffering>
          {/* <Offering></Offering> */}
          <Accordionsection></Accordionsection>
          <Whychooseus></Whychooseus>
          <Whysection />
{/*   <RegButton /> */}
          <br />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
