import React from "react";
import { LoaderLogo, wordedLogo } from "../constants/import";

const Spinner = () => {
  return (
    <div className="loadingSpinnerContainer">
      <div className="flex items-center justify-center">
        <img
          // className="spinning-image "
          className="animate-spin w-[150px] "
          alt="Logo"
          src={LoaderLogo}
        />
        <img src={wordedLogo} alt="Logo" className="w-[200px]" />
      </div>
    </div>
  );
};

export default Spinner;
