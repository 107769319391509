// src/components/SurveyForm.js
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

const SurveyForm = ({
  formData,
  setFormData,
  onActiveStepOne,
  setPin,
  setHasAccess,
}) => {
  const [loading, setLoading] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for empty fields before submitting
    if (
      formData.topics === "" ||
      formData.topicRating === "" ||
      formData.areasOfInterest === "" ||
      formData.interestRating === "" ||
      formData.comments === ""
    ) {
      toast.error("Please all Fields Required");
    } else {
      setLoading(true);
      axios
        .post("https://kbwprjs.com/main/api/sclbreakfast", formData)
        .then((response) => {
          setLoading(false);
          if (response.data?.code === 200) {
            toast.success("Survey Successfully Submitted");
            setFormData({
              topics: "",
              topicRating: "",
              areasOfInterest: "",
              interestRating: "",
              comments: "",
              firstname: "",
              lastname: "Nill",
            });
            setHasAccess(false);
            setPin("");
            onActiveStepOne();
          } else {
            toast.error(response.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error submitting the form:", error);
          toast.error("Error Occured while saving Survey");
        });
    }
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <form className="max-w-sm mx-auto mt-6" onSubmit={handleSubmit}>
      <h1 className="my-8 text-xl font-bold text-green-600">
        SCL Bi-Weekly Breakfast Session Questionnaire
      </h1>
      {/* Question 1 */}
      <div className="mb-4">
        <label
          htmlFor="topics"
          className="block text-gray-700 font-medium mb-3"
        >
          What topics would you like to be considered for the bi-weekly
          breakfast session?
        </label>
        <textarea
          name="topics"
          id="topics"
          value={formData.topics}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-500"
          rows="4"
        />
      </div>

      {/* Question 2 */}
      <div className="mb-4">
        <label
          htmlFor="topicRating"
          className="block text-gray-700 font-medium mb-3"
        >
          Please rate your suggested topics for inclusion in order of preference
          (starting with the most preferred)
        </label>
        <textarea
          name="topicRating"
          id="topicRating"
          value={formData.topicRating}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-500"
          rows="4"
        />
      </div>

      {/* Question 3 */}
      <div className="mb-4">
        <label
          htmlFor="areasOfInterest"
          className="block text-gray-700 font-medium mb-3"
        >
          What areas of interest as it affects your work would you like to see
          included in the bi-weekly breakfast sessions?
        </label>
        <textarea
          name="areasOfInterest"
          id="areasOfInterest"
          value={formData.areasOfInterest}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-500"
          rows="4"
        />
      </div>

      {/* Question 4 */}
      <div className="mb-4">
        <label
          htmlFor="interestRating"
          className="block text-gray-700 font-medium mb-3"
        >
          Please rate your suggested areas of interest for inclusion in order of
          preference (starting with the most preferred)
        </label>
        <textarea
          name="interestRating"
          id="interestRating"
          value={formData.interestRating}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-500"
          rows="4"
        />
      </div>

      {/* Question 5 */}
      <div className="mb-4">
        <label
          htmlFor="comments"
          className="block text-gray-700 font-medium mb-3"
        >
          Any other comments that will help to improve the bi-weekly breakfast
          sessions?
        </label>
        <textarea
          name="comments"
          id="comments"
          value={formData.comments}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-500"
          rows="4"
        />
      </div>

      {/* Submit button */}
      <div className="text-center">
        <button
          type="submit"
          className="bg-green-600 text-white px-10 py-2 rounded hover:bg-primary-600 mb-8"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SurveyForm;
