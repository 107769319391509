import React from "react";
import agriculture from "../assets/images/icon/agriculture.png";
import chip from "../assets/images/icon/chip.png";
import community from "../assets/images/icon/community.png";
import plant from "../assets/images/icon/plant.png";



const Whychooseus = () => {
    return(
        <div className="w-full px-2 sm:px-4 md:px-8 lg:px-32 py-32 ">
             <h2 className=" py-8 lg:text-3xl text-gray-700 sm:text-l font-bold" >Why Choose SCL Agro Allied Farm</h2>

             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6 w-full">
                <div className=" p-8">
             <img className="w-full w-20" src={plant} />

                <h2 className=" py-4  lg:text-xl text-gray-700 sm:text-l font-bold" >Farm-to-Table Integrity</h2>
                <p className="leading-relaxed lg:text-lg">With complete control over the production process, we ensure the highest standards of quality, freshness, and traceability, from our fields to your table.</p>

                </div>
                <div className=" p-8">
            <img className="w-full w-20" src={chip} />

                <h2 className=" py-4  lg:text-xl text-gray-700 sm:text-l font-bold" >Sustainability </h2>
                <p className="leading-relaxed lg:text-lg">We believe in treading lightly on the earth. Our regenerative practices and resource-efficient methods reflect our commitment to a sustainable future.</p>

                </div>
                <div className=" p-8">
             <img className="w-full w-20" src={community} />
                <h2 className=" py-4   lg:text-xl text-gray-700 sm:text-l font-bold" >Community Focus</h2>
                <p className="leading-relaxed lg:text-lg"> SCL Agro Allied Farm is rooted in community values. We support local economies, empower farmers, and actively participate in rural development initiatives.</p>

                </div>
                <div className=" p-8">
             <img className="w-full w-20" src={agriculture} />
                <h2 className=" py-4 lg:text-xl text-gray-700 sm:text-l font-bold" >Passion for Agriculture</h2>
                <p className="leading-relaxed lg:text-lg">Agriculture is not just a business; it's our passion. Our love for the land drives us to continually improve and innovate for a thriving agro-allied sector.</p>

                </div>
                
             </div>
        </div>
    )
}

export default Whychooseus;