import React from "react";
import ImageSlider from "../Image-slider/ImageSlider";
import { SliderData } from "../Image-slider/SliderData";
import GalleryContainer from "./GalleryContainer";
import gallery1 from "../../assets/images/gallery1.png";
import gallery2 from "../../assets/images/gallery2.png";
import gallery3 from "../../assets/images/gallery3.png";
import ImageCard from "./ImageCard";
const Home = () => {
  return (
    <div className="py-10 md:px-28 px-12">
      <h1 className="font-medium text-green-900 md:text-2xl text-lg">
        Masterpieces in Action:
      </h1>
      <h1 className="font-light text-green-900 md:text-2xl text-lg">
        Showcasing Our Journey
      </h1>
      {/* Gallery Card */}
      <div className="flex justify-between items-center flex-wrap gap-4 my-8">
        <ImageCard
          imageUrl={gallery1}
          description="Onboarding for project juriya in kwali Farm. Dec, 2022"
        />
        <ImageCard
          imageUrl={gallery2}
          description="Onboarding for project juriya in kwali Farm. Dec, 2022"
        />
        <ImageCard
          imageUrl={gallery3}
          description="Onboarding for project juriya in kwali Farm. Dec, 2022"
        />
      </div>
    </div>
  );
};

export default Home;
