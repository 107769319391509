import React from "react";

export default () => {
  return (
    <div className="w-full h-screen">
      <div>
        <iframe
          title="SCL Project Juriya - Year 1 FAs Performance Dashboard"
         width="100%"
          height="600"
          src="https://app.powerbi.com/view?r=eyJrIjoiYzQxOWM3YjktMzVkYy00ZWEwLTk1MzMtNTQ2MTkzYmMyMmYwIiwidCI6ImQ3MTg2MGNiLTE4MWQtNDQ0Zi05MDBjLTkyMjJhZjg2MDYwZCJ9"
          frameborder="0"
          allowFullScreen="true" >

        </iframe>
      </div>
    </div>
  );
};
