import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "./Form/InputField";
import SubHeader from "./Form/SubHeader";
import Spinner from "./Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { wardsList } from "../constants/wards";
import emailjs from "emailjs-com";
const FieldAgentForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    gender: "",
    email: "",
    phoneNumber: "",
    MDA: "",
    WhatMDA: "",
    yearsOfExperience: "",
    lat: "",
    lng: "",
    address: "",
    state: "",
    town: "",
    ward: "",
    climateChangeIdea: "",
    agroIdea: "",
    goalofRA: "",
    farmingPractice: "",
    compostPrinciple: "",
    cropPlantingTerm: "",
    soilManagementPractice: "",
    noTilling: "",
    coverCroping: "",
    livestockRole: "",
    benefitOfRA: "",
  });

  const [myDate, setMyDate] = useState(null);
  const [showLgaInput, setShowLgaInput] = useState(false);
  const [filteredLga, setFilteredLga] = useState([]);
  const [showWardsInput, setShowWardsInput] = useState(false);
  const [filteredWards, setFilteredWards] = useState({});
  const [onShowConsent, setOnShowConsent] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   formData.lat = position.coords.latitude;
    //   formData.lng = position.coords.longitude;
    // });
    navigator.geolocation.getCurrentPosition((position) => {
      setFormData((prevState) => ({
        ...prevState,
        lat: `${position.coords.latitude}`,
        lng: `${position.coords.longitude}`,
      }));
    });
  }, []);
  const {
    firstName,
    lastName,
    middleName,
    dob,
    gender,
    email,
    phoneNumber,
    MDA,
    WhatMDA,
    yearsOfExperience,
    lat,
    lng,
    address,
    state,
    town,
    ward,
    climateChangeIdea,
    agroIdea,
    goalofRA,
    farmingPractice,
    compostPrinciple,
    cropPlantingTerm,
    soilManagementPractice,
    noTilling,
    coverCroping,
    livestockRole,
    benefitOfRA,
  } = formData;
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onStateChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // item.state.toUpperCase() === state &&
    let filtered = wardsList.filter(
      (item) => item.state === e.target.value.toUpperCase()
    );
    setFilteredLga(filtered);
    setShowLgaInput(true);
  };

  const onLgaChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    const selected = wardsList.find(function (item) {
      return item.lga === e.target.value.toUpperCase();
    });
    setFilteredWards(selected);
    setShowWardsInput(true);
  };
  const emailValidation = () => {
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test(email)) {
      toast.error("Please Enter a Valid Email");
    }
  };
  // Phone Number Validation
  const phoneNumberValidation = () => {
    if (phoneNumber.length !== 11) {
      toast.error("Phone Number be 11 digits");
    }
  };
  const onDateChange = (date) => {
    let day = date.getDate();

    let month = date.getMonth();

    let year = date.getFullYear();
    setMyDate(date);
    const newDate = `${year}-${month + 1}-${day}`;
    setFormData((prevState) => ({
      ...prevState,
      dob: newDate,
    }));
    console.log(myDate);
  };
  const handleOptionChange = (e, inputName) => {
    setFormData((prevState) => ({
      ...prevState,
      [inputName]: e.target.value,
    }));
  };
  const onSubmit = async () => {
    // e.preventDefault();
    // setLoading(true);
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const firstThree = phoneNumber.slice(0, 3);
    const phoneNumberFormat = ["080", "081", "070", "090", "091"];
    const validNumber = phoneNumberFormat.find((item) => item === firstThree);
    console.log(formData);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !yearsOfExperience ||
      !address ||
      !state ||
      !town ||
      !dob ||
      !gender ||
      !MDA ||
      !ward ||
      !climateChangeIdea ||
      !agroIdea ||
      !goalofRA ||
      !farmingPractice ||
      !compostPrinciple ||
      !cropPlantingTerm ||
      !soilManagementPractice ||
      !noTilling ||
      !coverCroping ||
      !livestockRole ||
      !benefitOfRA
    ) {
      setLoading(false);
      toast.error("All fields are mandatory!");
    } else if (!regEx.test(email)) {
      setLoading(false);
      // Check Valid Email
      toast.error("Please Enter a Valid Email");
    } else if (phoneNumber.length !== 11) {
      // Phone Number Validation
      setLoading(false);
      toast.error("Phone Number be 11 digits");
    } else if (!validNumber) {
      setLoading(false);
      toast.error(
        "Phone Number Must Start with any of this Format (080-090-070-081-091)"
      );
    } else if (!lat && !lng) {
      setLoading(false);
      toast.error(
        "Please Refresh Your Browser and Allow Us to Access Your Location"
      );
    } else if (climateChangeIdea.length > 250) {
      setLoading(false);
      toast.error(
        "ideas about the impact of climate change should not be more than 40 Characters"
      );
    } else if (agroIdea.length > 250) {
      setLoading(false);
      toast.error(
        "Ideas on the use of agro allied chemicals on plant, animals and environement"
      );
    } else {
      setLoading(true);
      // http://127.0.0.1/new-back/index.php

      axios
        .post("https://honeywealthfoods.ca/new-back/index.php", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          setLoading(false);
          navigate(`/`);
          toast.success("Registration Successful!!!");
          setFormData((prevState) => ({
            ...prevState,
            firstName: "",
            lastName: "",
            middleName: "",
            nPowerNumber: "",
            email: "",
            phoneNumber: "",
            yearsOfExperience: "",
            MDA: "",
            WhatMDA: "",
            address: "",
            state: "",
            town: "",
            dob: "",
            gender: "",
            climateChangeIdea: "",
            agroIdea: "",
            goalofRA: "",
            farmingPractice: "",
            compostPrinciple: "",
            cropPlantingTerm: "",
            soilManagementPractice: "",
            noTilling: "",
            coverCroping: "",
            livestockRole: "",
            benefitOfRA: "",
          }));
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
          console.log(error.message);
        });
      // console.log(formData);
      // fetch("http://127.0.0.1/new-back/index.php", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(formData),
      // })
      //   .then((response) => response.text())
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
      // setLoading(true);
      // axios({
      //   method: "post",
      //   url: "http://127.0.0.1:8000/api/add-field-agent",
      //   // url: "https://honeywealthfoods.ca/api/add-field-agent",
      //   data: formData,
      //   headers: { "Content-Type": "multipart/form-data" },
      // })
      //   .then(async (res) => {
      //     // var templateParams = {
      //     //   name: "James",
      //     //   notes: "Check this out!",
      //     // };

      //     // await emailjs
      //     //   .send(
      //     //     "service_v03ai27",
      //     //     "template_mpbyr6k",
      //     //     templateParams,
      //     //     "mseWErsui36syGP-7"
      //     //   )
      //     //   .then(
      //     //     function (response) {
      //     //       console.log("SUCCESS!", response.status, response.text);
      //     //     },
      //     //     function (error) {
      //     //       console.log("FAILED...", error);
      //     //     }
      //     //   );
      //     setLoading(false);
      //     setFormData((prevState) => ({
      //       ...prevState,
      //       firstName: "",
      //       lastName: "",
      //       middleName: "",
      //       nPowerNumber: "",
      //       email: "",
      //       phoneNumber: "",
      //       yearsOfExperience: "",
      //       address: "",
      //       state: "",
      //       town: "",
      //       dob: "",
      //       climateChangeIdea: "",
      //       agroIdea: "",
      //       goalofRA: "",
      //       farmingPractice: "",
      //       compostPrinciple: "",
      //       cropPlantingTerm: "",
      //       soilManagementPractice: "",
      //       livestockRole: "",
      //       benefitOfRA: "",
      //     }));
      //     navigate(`/`);
      //     toast.success("Registration Successful!!!");
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     toast.error(error.message);
      //     console.log(error.message);
      //   });
    }
  };
  const flexClass = "lg:flex block justify-between items-center w-full";
  // if (loading) return <Spinner />;
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="lg:w-[450px] w-full lg:mx-auto mb-32 mt-14">
          {/* <div className="lg:w-[450px] w-full lg:mx-auto mx-0 px-10 mb-32 mt-14"> */}
          <div className="">
            <h1 className="text-xl md:text-3xl font-bold text-black text-center mr-3">
              SCL Project Juriya
            </h1>
            <p className="text-green-400 md:font-medium font-normal lg:text-lg text-sm text-center mr-3">
              Field Agent Registration Form
            </p>
            <div>
              {onShowConsent ? (
                <>
                  <h1 className="text-xl my-2 font-bold text-black text-center">
                    Attention
                  </h1>
                  <p className="text-black text-justify my-4 text-sm">
                    I agree to participate in this survey, and I acknowledge
                    that I am 18 years of age or older and will provide the
                    necessary information as required. I have been given
                    adequate information about the aim of the survey with the
                    understanding that I am free to withdraw my participation in
                    the survey without penalty. Do you agree to participate in
                    the survey?
                  </p>
                  <div className="flex justify-between items-center my-10">
                    <button
                      onClick={() => {
                        navigate(`/`);
                      }}
                      className="border py-2 px-8 border-green-500 text-green-500 rounded-md font-bold"
                    >
                      No
                    </button>
                    <button
                      onClick={() => setOnShowConsent(false)}
                      className="py-2 px-8 bg-green-500 text-white rounded-md font-bold"
                    >
                      Yes
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-xs text-red-500 font-medium text-center">
                    All Fields are Mandatory!
                  </p>
                  <form className="flex lg:mx-0 mx-10 items-center lg:px-2">
                    {/* Left Panel */}
                    <div
                      className="relative"
                      // style={{ width: "450px", marginRight: "50px" }}
                    >
                      {/* PersonalInformation */}
                      <div>
                        <SubHeader title={"Personal Information"} />
                        <div className={flexClass}>
                          <InputField
                            title={"First Name"}
                            nameId={"firstName"}
                            value={firstName}
                            onChange={onChange}
                            required={true}
                          />
                          <InputField
                            title={"Middle Name (Optional)"}
                            nameId={"middleName"}
                            value={middleName}
                            onChange={onChange}
                          />
                        </div>
                        <div className={flexClass}>
                          <InputField
                            title={"Last Name"}
                            nameId={"lastName"}
                            value={lastName}
                            onChange={onChange}
                            required={true}
                          />

                          <div className="flex items-center justify-between px-2 lg:h-[40px] border border-black rounded-sm">
                            <div className="mr-1">
                              <p className="font-medium text-black text-sm  ">
                                DOB
                              </p>
                              <p
                                className="text-red-500"
                                style={{ fontSize: "9.5px" }}
                              >
                                At least 18yrs
                              </p>
                            </div>
                            <input
                              type="date"
                              name="dob"
                              id="dob"
                              max="2003-12-31"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        {/*Gender */}
                        <div className="my-6">
                          <p className="text-black font-medium my-2 mb-2">
                            What is your Gender?
                          </p>
                          <div className="flex items-center">
                            <label className="flex my-2 items-center mr-5">
                              <input
                                type="radio"
                                value={"Male"}
                                checked={gender === "Male"}
                                onChange={(e) =>
                                  handleOptionChange(e, "gender")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">Male</p>
                            </label>
                            <label className="flex my-2 items-center">
                              <input
                                type="radio"
                                value={"Female"}
                                checked={gender === "Female"}
                                onChange={(e) =>
                                  handleOptionChange(e, "gender")
                                }
                              />
                              <p className="pl-3 text-black">Female</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* Address */}
                      <div>
                        <SubHeader title={"Address"} />
                        <div>
                          <div className="w-full">
                            <InputField
                              title={"What is your Home Address?"}
                              nameId={"address"}
                              value={address}
                              onChange={onChange}
                              required={true}
                            />
                            <br />
                          </div>
                          <div>
                            {/* State Dropdown */}
                            <div
                            // className="form-group border border-black rounded-sm py-1 px-2"
                            // style={{ width: "45%" }}
                            >
                              <select
                                onChange={onStateChange}
                                name="state"
                                id="state"
                                className="form-group border border-black rounded-sm py-1 px-4 w-full"
                              >
                                <option value="">
                                  - What is your State of Residence? -
                                </option>
                                {/* <option value="Abia">Abia</option> */}
                                <option value="ADAMAWA">Adamawa</option>
                                {/* <label className="control-label">State</label> */}
                                {/* <option value="AkwaIbom">AkwaIbom</option>
                    <option value="Anambra">Anambra</option> */}
                                <option value="BAUCHI">Bauchi</option>
                                {/* <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option> */}
                                <option value="ABUJA-FCT">ABUJA-FCT</option>
                                {/* <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option> */}
                                <option value="KADUNA">Kaduna</option>
                                <option value="KANO">Kano</option>
                                {/* <option value="Katsina">Katsina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option> */}
                                <option value="NASARAWA">Nasarawa</option>
                                {/* <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>*/}
                                <option value="TARABA">Taraba</option>
                                {/* <option value="Yobe">Yobe</option>
                    <option value="Zamfara">Zamafara</option> */}
                              </select>
                            </div>
                            {/* Local Government Input */}
                            {showLgaInput && (
                              <select
                                onChange={onLgaChange}
                                name="town"
                                id="town"
                                className="form-group border border-black rounded-sm py-1 px-4 w-full my-6"
                              >
                                <option value="">
                                  - What is your LGA of Residence? -
                                </option>
                                {filteredLga.map((item, index) => (
                                  <option value={item.lga} key={index}>
                                    {item.lga}
                                  </option>
                                ))}
                              </select>
                            )}
                            {/* Wards Input */}
                            {showWardsInput && (
                              <select
                                onChange={onChange}
                                name="ward"
                                id="ward"
                                className="form-group border border-black rounded-sm py-1 px-4 w-full "
                              >
                                <option value="">- What is your Ward? -</option>
                                {filteredWards.wards.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Conact Information */}
                      <div className="mb-5">
                        <SubHeader title={"Contact Information"} />
                        <div className={flexClass}>
                          <InputField
                            title={"Email Address"}
                            nameId={"email"}
                            value={email}
                            onChange={onChange}
                            required={true}
                            type="email"
                          />
                          <div>
                            <InputField
                              title={"Phone Number"}
                              nameId={"phoneNumber"}
                              value={phoneNumber}
                              onChange={onChange}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        {/* Years of yearsOfExperience */}
                        <div className="form-group my-8 w-full">
                          <label className="control-label text-black block my-3">
                            Years of Experience{" "}
                          </label>
                          <select
                            onChange={onChange}
                            name="yearsOfExperience"
                            id="yearsOfExperience"
                            className="form-group border border-black rounded-sm py-1 px-2"
                            style={{ width: "100%" }}
                          >
                            <option value={""}>
                              -- Years of Experience --
                            </option>
                            <option value={"1-3"}>{"1-3 Years"}</option>
                            <option value={"4-6"}>{"4-6 Years"}</option>
                            <option value={"6+"}>{"6+"}</option>
                          </select>
                        </div>
                        {/*MDA */}
                        <div className="my-6">
                          <p className="text-black font-medium my-2 mb-2">
                            Are you working with any Organization or Government
                            Ministries, Departments & Agencies (MDA's)?
                          </p>
                          <div className="flex items-center">
                            <label className="flex my-2 items-center mr-5">
                              <input
                                type="radio"
                                value={"Yes"}
                                checked={MDA === "Yes"}
                                onChange={(e) => handleOptionChange(e, "MDA")}
                                className=""
                              />
                              <p className="pl-3 text-black">Yes</p>
                            </label>
                            <label className="flex my-2 items-center">
                              <input
                                type="radio"
                                value={"No"}
                                checked={MDA === "No"}
                                onChange={(e) => handleOptionChange(e, "MDA")}
                              />
                              <p className="pl-3 text-black">No</p>
                            </label>
                          </div>
                        </div>
                        {/* What MDA */}
                        {MDA === "Yes" && (
                          <div className="w-full">
                            <InputField
                              title={
                                "Please Specify the MDA you are working with?"
                              }
                              nameId={"WhatMDA"}
                              value={WhatMDA}
                              onChange={onChange}
                            />
                          </div>
                        )}
                        <br />
                        {/* Survey Questions */}
                        <div className="my-6">
                          <p className="text-black font-medium my-2 mb-2">
                            What is the primary goal of Regenerative
                            Agriculture?
                          </p>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Maximizing short-term crop yields"
                              checked={
                                goalofRA === "Maximizing short-term crop yields"
                              }
                              onChange={(e) =>
                                handleOptionChange(e, "goalofRA")
                              }
                              className=""
                            />
                            <p className="pl-3 text-black">
                              Maximizing short-term crop yields
                            </p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Minimizing the use of organic matter"
                              checked={
                                goalofRA ===
                                "Minimizing the use of organic matter"
                              }
                              onChange={(e) =>
                                handleOptionChange(e, "goalofRA")
                              }
                            />
                            <p className="pl-3 text-black">
                              Minimizing the use of organic matter
                            </p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Restoring and improving the health of the soil and ecosystem"
                              checked={
                                goalofRA ===
                                "Restoring and improving the health of the soil and ecosystem"
                              }
                              onChange={(e) =>
                                handleOptionChange(e, "goalofRA")
                              }
                            />
                            <p className="pl-3 text-black">
                              Restoring and improving the health of the soil and
                              ecosystem
                            </p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Increasing the use of synthetic chemicals"
                              checked={
                                goalofRA ===
                                "Increasing the use of synthetic chemicals"
                              }
                              onChange={(e) =>
                                handleOptionChange(e, "goalofRA")
                              }
                            />
                            <p className="pl-3 text-black">
                              Increasing the use of synthetic chemicals
                            </p>
                          </label>
                        </div>
                        {/*  */}
                        <div className="my-6">
                          <p className="text-black font-medium my-2 mb-2">
                            Which farming practice is NOT associated with
                            regenerative agriculture?
                          </p>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Crop rotation"
                              checked={farmingPractice === "Crop rotation"}
                              onChange={(e) =>
                                handleOptionChange(e, "farmingPractice")
                              }
                              className=""
                            />
                            <p className="pl-3 text-black">Crop rotation</p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="No-till farming"
                              checked={farmingPractice === "No-till farming"}
                              onChange={(e) =>
                                handleOptionChange(e, "farmingPractice")
                              }
                            />
                            <p className="pl-3 text-black">No-till farming</p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Monocropping"
                              checked={farmingPractice === "Monocropping"}
                              onChange={(e) =>
                                handleOptionChange(e, "farmingPractice")
                              }
                            />
                            <p className="pl-3 text-black">Monocropping</p>
                          </label>
                          <label className="flex my-2">
                            <input
                              type="radio"
                              value="Cover cropping"
                              checked={farmingPractice === "Cover cropping"}
                              onChange={(e) =>
                                handleOptionChange(e, "farmingPractice")
                              }
                            />
                            <p className="pl-3 text-black">Cover cropping</p>
                          </label>
                        </div>
                        {/*  */}
                        <div>
                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              What is the primary principle behind the use of
                              compost and organic matter in Regenerative
                              Agriculture?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Increasing soil erosion"
                                checked={
                                  compostPrinciple === "Increasing soil erosion"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "compostPrinciple")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Increasing soil erosion
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Providing habitat for pests"
                                checked={
                                  compostPrinciple ===
                                  "Providing habitat for pests"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "compostPrinciple")
                                }
                              />
                              <p className="pl-3 text-black">
                                Providing habitat for pests
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Enhancing soil fertility and microbial activity"
                                checked={
                                  compostPrinciple ===
                                  "Enhancing soil fertility and microbial activity"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "compostPrinciple")
                                }
                              />
                              <p className="pl-3 text-black">
                                Enhancing soil fertility and microbial activity
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Reducing water retention in the soil"
                                checked={
                                  compostPrinciple ===
                                  "Reducing water retention in the soil"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "compostPrinciple")
                                }
                              />
                              <p className="pl-3 text-black">
                                Reducing water retention in the soil
                              </p>
                            </label>
                          </div>

                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              What term is used to describe the process of
                              planting a variety of different crops in close
                              proximity to one another in Regenerative
                              Agriculture?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Monoculture"
                                checked={cropPlantingTerm === "Monoculture"}
                                onChange={(e) =>
                                  handleOptionChange(e, "cropPlantingTerm")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">Monoculture</p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Polyculture"
                                checked={cropPlantingTerm === "Polyculture"}
                                onChange={(e) =>
                                  handleOptionChange(e, "cropPlantingTerm")
                                }
                              />
                              <p className="pl-3 text-black">Polyculture</p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Crop rotation"
                                checked={cropPlantingTerm === "Crop rotation"}
                                onChange={(e) =>
                                  handleOptionChange(e, "cropPlantingTerm")
                                }
                              />
                              <p className="pl-3 text-black">Crop rotation</p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Synthetic farming"
                                checked={
                                  cropPlantingTerm === "Synthetic farming"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "cropPlantingTerm")
                                }
                              />
                              <p className="pl-3 text-black">
                                Synthetic farming
                              </p>
                            </label>
                          </div>

                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              Which of the following practices is a key element
                              of regenerative soil management?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Frequent deep ploughing"
                                checked={
                                  soilManagementPractice ===
                                  "Frequent deep ploughing"
                                }
                                onChange={(e) =>
                                  handleOptionChange(
                                    e,
                                    "soilManagementPractice"
                                  )
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Frequent deep ploughing
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Soil compaction through heavy machinery"
                                checked={
                                  soilManagementPractice ===
                                  "Soil compaction through heavy machinery"
                                }
                                onChange={(e) =>
                                  handleOptionChange(
                                    e,
                                    "soilManagementPractice"
                                  )
                                }
                              />
                              <p className="pl-3 text-black">
                                Soil compaction through heavy machinery
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Soil testing and nutrient management"
                                checked={
                                  soilManagementPractice ===
                                  "Soil testing and nutrient management"
                                }
                                onChange={(e) =>
                                  handleOptionChange(
                                    e,
                                    "soilManagementPractice"
                                  )
                                }
                              />
                              <p className="pl-3 text-black">
                                Soil testing and nutrient management
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Building organic matter and soil structure"
                                checked={
                                  soilManagementPractice ===
                                  "Building organic matter and soil structure"
                                }
                                onChange={(e) =>
                                  handleOptionChange(
                                    e,
                                    "soilManagementPractice"
                                  )
                                }
                              />
                              <p className="pl-3 text-black">
                                Building organic matter and soil structure
                              </p>
                            </label>
                          </div>

                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              What is the role of livestock in Regenerative
                              Agriculture?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Livestock are not part of Regenerative Agriculture practices"
                                checked={
                                  livestockRole ===
                                  "Livestock are not part of Regenerative Agriculture practices"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "livestockRole")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Livestock are not part of Regenerative
                                Agriculture practices
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Livestock are used to replace crop cultivation"
                                checked={
                                  livestockRole ===
                                  "Livestock are used to replace crop cultivation"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "livestockRole")
                                }
                              />
                              <p className="pl-3 text-black">
                                Livestock are used to replace crop cultivation
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Livestock helps enhance soil fertility through grazing and manure"
                                checked={
                                  livestockRole ===
                                  "Livestock helps enhance soil fertility through grazing and manure"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "livestockRole")
                                }
                              />
                              <p className="pl-3 text-black">
                                Livestock helps enhance soil fertility through
                                grazing and manure
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Livestock are raised solely for meat production"
                                checked={
                                  livestockRole ===
                                  "Livestock are raised solely for meat production"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "livestockRole")
                                }
                              />
                              <p className="pl-3 text-black">
                                Livestock are raised solely for meat production
                              </p>
                            </label>
                          </div>

                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              Which of the following is a potential benefit of
                              regenerative agriculture?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Increased soil degradation"
                                checked={
                                  benefitOfRA === "Increased soil degradation"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "benefitOfRA")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Increased soil degradation
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Greater reliance on chemical inputs"
                                checked={
                                  benefitOfRA ===
                                  "Greater reliance on chemical inputs"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "benefitOfRA")
                                }
                              />
                              <p className="pl-3 text-black">
                                Greater reliance on chemical inputs
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Improved resilience to climate change"
                                checked={
                                  benefitOfRA ===
                                  "Improved resilience to climate change"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "benefitOfRA")
                                }
                              />
                              <p className="pl-3 text-black">
                                Improved resilience to climate change
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Reduced biodiversity"
                                checked={benefitOfRA === "Reduced biodiversity"}
                                onChange={(e) =>
                                  handleOptionChange(e, "benefitOfRA")
                                }
                              />
                              <p className="pl-3 text-black">
                                Reduced biodiversity
                              </p>
                            </label>
                          </div>
                          {/* What is the practice of "no-till farming" in Regenerative Agriculture primarily aimed at achieving? */}
                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              What is the practice of "no-till farming" in
                              Regenerative Agriculture primarily aimed at
                              achieving?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Enhancing soil biodiversity"
                                checked={
                                  noTilling === "Enhancing soil biodiversity"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "noTilling")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Enhancing soil biodiversity
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Reducing soil compaction"
                                checked={
                                  noTilling === "Reducing soil compaction"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "noTilling")
                                }
                              />
                              <p className="pl-3 text-black">
                                Reducing soil compaction
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Increasing erosion"
                                checked={noTilling === "Increasing erosion"}
                                onChange={(e) =>
                                  handleOptionChange(e, "noTilling")
                                }
                              />
                              <p className="pl-3 text-black">
                                Increasing erosion
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Promoting weed growth"
                                checked={noTilling === "Promoting weed growth"}
                                onChange={(e) =>
                                  handleOptionChange(e, "noTilling")
                                }
                              />
                              <p className="pl-3 text-black">
                                Promoting weed growth
                              </p>
                            </label>
                          </div>
                          {/* What is the primary purpose of cover cropping in Regenerative Agriculture? */}
                          <div className="my-6">
                            <p className="text-black font-medium my-2 mb-2">
                              What is the primary purpose of cover cropping in
                              Regenerative Agriculture?
                            </p>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Providing food for wildlife"
                                checked={
                                  coverCroping === "Providing food for wildlife"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "coverCroping")
                                }
                                className=""
                              />
                              <p className="pl-3 text-black">
                                Providing food for wildlife
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Reducing soil erosion"
                                checked={
                                  coverCroping === "Reducing soil erosion"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "coverCroping")
                                }
                              />
                              <p className="pl-3 text-black">
                                Reducing soil erosion
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Eliminating the need for crop rotation"
                                checked={
                                  coverCroping ===
                                  "Eliminating the need for crop rotation"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "coverCroping")
                                }
                              />
                              <p className="pl-3 text-black">
                                Eliminating the need for crop rotation
                              </p>
                            </label>
                            <label className="flex my-2">
                              <input
                                type="radio"
                                value="Maximizing cash crop production"
                                checked={
                                  coverCroping ===
                                  "Maximizing cash crop production"
                                }
                                onChange={(e) =>
                                  handleOptionChange(e, "coverCroping")
                                }
                              />
                              <p className="pl-3 text-black">
                                Maximizing cash crop production
                              </p>
                            </label>
                          </div>
                        </div>
                        {/* Climate Change */}
                        <div>
                          <p className="text-black my-2">
                            What are your ideas about the impact of climate
                            change on Africa?
                          </p>
                          <textarea
                            placeholder="40 Character Max"
                            rows={2}
                            className="w-full border border-black rounded-sm p-2"
                            name="climateChangeIdea"
                            id="climateChangeIdea"
                            onChange={onChange}
                          ></textarea>
                        </div>
                        <div>
                          <p className="text-black my-2">
                            What are your ideas on the use of Agro-allied
                            Chemicals on Plants, Farm Animals and
                            the Environments?
                          </p>
                          <textarea
                            placeholder="40 Character Max"
                            rows={2}
                            className="w-full border border-black rounded-sm p-2"
                            name="agroIdea"
                            id="agroIdea"
                            onChange={onChange}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        className="bg-black text-white px-14 py-2 font-medium rounded-md absolute right-0 mt-5"
                        onClick={() => onSubmit()}
                        type="button"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FieldAgentForm;
