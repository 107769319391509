import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { colors } from "../constants/theme";
import { toast } from "react-toastify";

const Acresal = () => {
  return (
    <div className="w-full">
      <div className="w-full">
        <Navbar />
        {/* <div
          style={{ backgroundColor: colors.primary }}
          className="bg-primary h-screen flex flex-col justify-center items-center"
        >
          <div className="text-white text-center">
            <h1 className="text-5xl md:text-10xl font-semibold mb-6">
              ACReSAL Adamawa
            </h1>
            <h1 className="text-2xl md:text-4xl font-semibold mb-6">
              Coming Soon
            </h1>
            <p className="text-lg md:text-xl mb-8">
              We're working hard to bring you something awesome. Stay tuned!
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="#"
                className="text-gray-300 hover:text-gray-100 transition duration-300"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2 18a20 20 0 1016-6 20 20 0 00-16 6z"
                  ></path>
                </svg>
              </a>
              <a
                href="#"
                className="text-gray-300 hover:text-gray-100 transition duration-300"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.5 13.5l2.96 2.96M7 10V3M7 3h10a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V5a2 2 0 012-2z"
                  ></path>
                </svg>
              </a>
              <a
                href="#"
                className="text-gray-300 hover:text-gray-100 transition duration-300"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 3v18l8-9-8-9zm14 0v18l-8-9 8-9z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div> */}



          
    <div   className="w-full h-[100vh]">

<iframe height='100%' width= '100%' src='https://acresal.kbwprjs.com/login'/>

    </div>
        <Footer />
      </div>
    </div>
  );
};

export default Acresal;
