import Footer from "./Footer";
import Navbar from "./Navbar";

export default function (){

 window.open('https://greentechsolution.tech/pt/juriya-project-management/', '_blank');
    return (<>
    
    <Navbar/>
    
    <div   className="w-full h-[100vh]">

<iframe height='100%' width= '100%' src='https://greentechsolution.tech/pt/juriya-project-management/'/>

    </div>

    <Footer/>
    </>)
}   