// src/components/SurveyInfo.js
import React from "react";
import { toast } from "react-toastify";

const SurveyInfo = ({ onActiveStepTwo, formData, onChange }) => {
  return (
    <div className="max-w-sm mx-auto mt-6">
      <h1 className="my-8 text-xl font-bold text-green-600">
        SCL Bi-Weekly Breakfast Session Questionnaire
      </h1>
      <p className="mb-4 text-black">
        Welcome and thank you for accepting to participate in this survey. This
        survey was developed to harness information from staff members to help
        design the breakfast session topics of interest. The breakfast session
        will help build a culture of continuous learning and growth, and skills
        development solutions for SCL staff.
      </p>
      <div className="border border-gray-300 rounded p-4 mb-4">
        <p className="mb-2 font-semibold text-red-700">Consent Disclaimer:</p>
        <p className="text-sm text-black">
          I,{" "}
          <input
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={onChange}
            className=" border-b border-b-gray-500  px-2 py-1 focus:outline-none focus:border-green-500"
            placeholder="Type your Name"
          />
          , voluntarily agree to participate in this survey. I understand that
          my responses will be kept confidential and will only be used for the
          purpose of designing the breakfast session topics of interest. I have
          been informed about the purpose and nature of this survey and have had
          the opportunity to ask questions.
        </p>
      </div>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        onClick={() => {
          if (formData.firstname === "") {
            toast.error("Please your name before you continue");
          } else {
            onActiveStepTwo();
          }
        }}
      >
        Accept and Proceed
      </button>
    </div>
  );
};

export default SurveyInfo;
