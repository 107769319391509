import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { colors } from "../constants/theme";
import { toast } from "react-toastify";
import SurveyForm from "../components/SurveyForm";
import SurveyInfo from "../components/SurveyInfo";

const Survey = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [pin, setPin] = useState("");
  const [onShowStepOne, setOnShowStepOne] = useState(true);
  const [onShowStepTwo, setOnShowStepTwo] = useState(false);

  const [formData, setFormData] = useState({
    topics: "",
    topicRating: "",
    areasOfInterest: "",
    interestRating: "",
    comments: "",
    firstname: "",
    lastname: "Nill",
  });
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  function onAcess(e) {
    console.log(pin);
    e.preventDefault();
    if (pin === "5881") {
      setHasAccess(true);
      toast.success("Access Granted");
    } else {
      toast.error("Invalid Access Pin");
    }
  }
  function onActiveStepOne() {
    setOnShowStepOne(true);
    setOnShowStepTwo(false);
  }
  function onActiveStepTwo() {
    setOnShowStepOne(false);
    setOnShowStepTwo(true);
  }
  return (
    <div className="w-full">
      <div className="w-full">
        <Navbar />
        {hasAccess ? (
          <div className="my-10">
            {onShowStepOne ? (
              <SurveyInfo
                onActiveStepTwo={onActiveStepTwo}
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
              />
            ) : (
              <SurveyForm
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                onActiveStepOne={onActiveStepOne}
                setPin={setPin}
                setHasAccess={setHasAccess}
              />
            )}
          </div>
        ) : (
          <div className="w-full h-[600px] flex-row justify-center items-center">
            <center>
              <div className="w-[50%] py-40">
                <h1 className="text-xl text-black">
                  Please Enter Pin to authorize access
                </h1>
                <form>
                  <div className="my-3">
                    <label class="block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Access Pin
                      </span>
                      <input
                        type="password"
                        class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                        placeholder="Access Pin"
                        onChange={(e) => setPin(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className=" font-semibold rounded-lg py-2 justify-center w-full text-white flex items-center"
                    style={{ backgroundColor: colors.primary }}
                    onClick={onAcess}
                  >
                    {"Get Access"}
                  </button>
                </form>
              </div>
            </center>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Survey;
